<template>
  <div>
    <GeneralCard>
      <FeeWalletStatic />
    </GeneralCard>
    <FeeWalletPayment />
  </div>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import FeeWalletStatic from "@/components/report/modals/addCashIn/elements/FeeWalletStatic.vue"
import FeeWalletPayment from "@/components/report/modals/addCashIn/elements/FeeWalletPayment.vue"

export default {
  name: "ManualCashIn",
  components: { FeeWalletPayment, GeneralCard, FeeWalletStatic },
}
</script>

<style lang="scss" scoped></style>
