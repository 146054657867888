<template>
  <LeftModal toolbar-name="کیف‌ پول وندار" @closeModal="$emit('close-modal')">
    <div class="box-style">
      <v-row>
        <v-col class="d-flex align-center">
          <v-img
            v-if="walletData?.icon"
            width="30"
            max-height="30"
            max-width="30"
            height="30"
            class="ml-2"
            :src="walletData.icon"
          />
          <span class="font_22">وندار </span>
        </v-col>
      </v-row>

      <WalletDetailRow
        text="موجودی کل"
        :value="
          balanceAmountWithThousandSeparatorForRialAmount(walletData.balance)
        "
      />
      <WalletDetailRow
        text="قابل برداشت"
        :value="
          balanceAmountWithThousandSeparatorForTomanAmount(
            walletAmountDetails.walletDeductibleAmount
          )
        "
      />
      <WalletDetailRow
        v-if="walletAmountDetails.paymentFacilitatorNonDeductibleAmount"
        text="مبالغ تجمیعی شاپرک (غیر قابل برداشت)"
        :value="
          balanceAmountWithThousandSeparatorForTomanAmount(
            walletAmountDetails.paymentFacilitatorNonDeductibleAmount
          )
        "
      />
      <WalletDetailRow
        v-if="walletAmountDetails.feeNonDeductibleAmount"
        text="کیف پول کارمزدی (غیرقابل برداشت)"
        :value="
          balanceAmountWithThousandSeparatorForTomanAmount(
            walletAmountDetails.feeNonDeductibleAmount
          )
        "
      />
      <WalletDetailRow
        v-if="walletAmountDetails.directDebitNonDeductibleAmount"
        text="مبالغ تجمیع شده پرداخت خودکار (غیرقابل برداشت)"
        :value="
          balanceAmountWithThousandSeparatorForTomanAmount(
            walletAmountDetails.directDebitNonDeductibleAmount
          )
        "
      />
    </div>
    <A2aDailyCard
      v-if="isA2aActive && !noActiveBank"
      :is-there-bank-accounts="true"
      class="mt-4"
      :class="{
        'pb-6': reuseIsMobile,
        'pb-14': $vuetify.breakpoint.smAndUp,
      }"
      @noBankIsActive="noActiveBank = true"
    />
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal"
import A2aDailyCard from "@/components/A2A/elements/a2aDailyCard"
import screenSize from "@/mixins/screenSize.js"
import currencyConvert from "@/mixins/currencyConvert.js"
import WalletDetailRow from "@/components/businessHome/elements/WalletDetailRow.vue"

export default {
  name: "WalletDetails",
  components: {
    LeftModal,
    A2aDailyCard,
    WalletDetailRow,
  },
  mixins: [screenSize, currencyConvert],
  props: {
    walletData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noActiveBank: false,
    }
  },
  computed: {
    isA2aActive() {
      return this.$store.state.business?.tools?.A2A?.status === "ACTIVE"
    },
    walletAmountDetails() {
      return this.$store.state.walletAmountDetails
    },
  },
  methods: {
    balanceAmountWithThousandSeparatorForRialAmount(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForRialAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
    balanceAmountWithThousandSeparatorForTomanAmount(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForTomanAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
  },
}
</script>

<style lang="scss" scoped>
.box-style {
  border: 1px solid #e5e5e5;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
}
</style>
