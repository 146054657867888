<template>
  <LeftModal
    ref="leftModal"
    toolbar-name="درخواست برداشت"
    @closeModal="$emit('closeModal', { status: false })"
  >
    <div slot="toolbarItems">
      <v-btn
        ref="btn"
        text
        dark
        class="btn_small_normal"
        :disabled="disableSubmitBtn"
        @click="openConfirmationModal"
      >
        ثبت در خواست
      </v-btn>
    </div>

    <template v-if="queueNotSeen" #subToolbar>
      <van-modal-announcement-bar
        description="امکان ثبت درخواست برداشت در نوبت، در صورت نداشتن موجودی کافی."
        container-classes="px-8 py-3 van_pink van_color13--text font-size-12 justify-space-between "
      >
        <template #iconSlot>
          <img
            width="24px"
            height="24px"
            src="@/assets/img/newIcon/whatsnew-white.svg"
          />
          <span class="mr-2 font-size-14 bold">جدید</span>
        </template>
        <template #actionSlot>
          <span style="border-bottom: 1px dashed #fff">
            <a class="van_color13--text" @click="showDetailsForQueue(true)"
              >جزییات</a
            >
          </span>
        </template>
      </van-modal-announcement-bar>
    </template>

    <GeneralCard>
      <v-form ref="form">
        <BankAccountsSelectBoxVue
          @origin-account-selected="setSelectedOriginAccount"
        />
        <IbanBox
          v-if="selectedOriginAccount"
          label="شماره شبا مقصد"
          :curve-all-corners="cellingSkeleton || !showCellingBox"
          :iban-is-in-black-list="ibanIsInBlackList"
          :is-selected-origin-account-cash-wallet="
            isSelectedOriginAccountCashWallet
          "
          @setSelectedIban="setSelectedIban($event)"
          @resetBlackListStatus="ibanIsInBlackList = false"
        >
          <template #bankIcon>
            <v-progress-circular
              v-if="bankIconSkeleton"
              class="ml-3 logo-display-320"
              value="0"
              indeterminate
              width="4"
              :size="$vuetify.breakpoint.xsOnly ? '40' : '44'"
              color="van_color06"
            />
            <v-flex
              v-else
              :class="[
                'selectedBankLogo ml-3 logo-display-320',
                hasA2aBank && selectedBank ? '' : 'pa-2 boxed',
              ]"
            >
              <!--bank logo-->
              <v-img
                v-if="!(hasA2aBank && selectedBank)"
                size="30"
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    selectedIban[0].slice(5, 7)
                  )}.svg`)
                "
              />
              <A2aDailyCardItem
                v-else
                :bank="selectedBank"
                :is-need-to-show-progress="isNeedToShowProgress"
              ></A2aDailyCardItem>
            </v-flex>
          </template>
        </IbanBox>
        <!--after iban-->
        <div v-if="selectedIban && selectedOriginAccount">
          <!--skeleton-->
          <v-layout v-if="cellingSkeleton" class="justify-space-between">
            <v-flex class="label"> </v-flex>
            <v-flex class="withlabel big py-3">
              <div class="w-100 font_2 bold">
                <span class="default_gray mx-auto" />
              </div>
            </v-flex>
          </v-layout>

          <!--iban celling for today is used-->
          <v-layout
            v-else-if="
              ibanCellingIsFinished && isSelectedOriginAccountCashWallet
            "
            class="d-flex justify-space-between mt-2 mb-0"
          >
            <v-flex class="label"> </v-flex>
            <v-flex
              class="van-pink-2 d-flex withlabel big font_2 pa-3 radius-4 justify-space-between"
            >
              سقف کلی شبا
              <span class="mr-auto">0</span>
            </v-flex>
          </v-layout>
          <!--celling box-->
          <IbanCelling
            v-else-if="
              selectedIban &&
              showCellingBox &&
              isSelectedOriginAccountCashWallet
            "
          >
            <IbanCellingItems
              v-for="row in ibanCellingArray"
              :key="row.label"
              v-bind="row"
              @showIbanDailyLimitModal="showIbanDailyLimitModal()"
            />
          </IbanCelling>
        </div>

        <!--amount and extra filed-->
        <div v-if="showAmountFiled && selectedOriginAccount">
          <!--amount-->
          <v-layout
            v-if="selectedIban"
            :column="$vuetify.breakpoint.xsOnly"
            class="justify-space-between mt-8"
          >
            <v-flex class="label">
              مبلغ
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-text-field
                ref="amount"
                v-model="amountFiled"
                :suffix="currencyName"
                :rules="amountRules"
                flat
                solo
                height="40"
                outlined
                autofocus
                @paste="handlePaste"
                @keydown="numberKeyHandler"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
              <TransitionGroup name="list" tag="div">
                <div
                  v-for="(item, index) in hintsArray"
                  :key="item + index"
                  class="d-flex mt-1"
                >
                  <template v-if="item.showCondition">
                    <v-img
                      class="ml-2"
                      :src="require(`@/assets/img/${item.iconName}.svg`)"
                      max-width="16"
                      max-height="10"
                    />
                    <span class="font_8">
                      {{ item.text }} {{ currencyName }}
                    </span>
                  </template>
                </div>
              </TransitionGroup>
            </v-flex>
          </v-layout>

          <!-- show settlement in queue message -->
          <v-layout
            v-if="
              showSettlementMethods &&
              !checkAmount &&
              isSelectedOriginAccountCashWallet
            "
            :column="$vuetify.breakpoint.xsOnly"
            class="justify-space-between mt-3"
          >
            <v-flex class="lable"></v-flex>
            <v-flex class="withlabel big">
              <div
                class="d-flex align-center font-size-12 px-3 py-2"
                style="background-color: #f9d35833"
              >
                <div class="van_color03--text">
                  به علت کمبود موجودی کیف‌پول، در صورت ثبت درخواست، تراکنش بعد
                  از افزایش موجودی در صف بانک قرار می‌گیرد.
                </div>
                <div>
                  <a
                    class="ma-0 van_color02--text"
                    style="border-bottom: 1px dashed"
                    @click="showDetailsForQueue()"
                    >جزییات</a
                  >
                </div>
              </div>
            </v-flex>
          </v-layout>

          <!--settlement method-->
          <v-layout
            v-if="showSettlementMethods"
            :column="$vuetify.breakpoint.xsOnly"
            class="justify-space-between mt-3"
          >
            <v-flex class="label">
              روش برداشت
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-radio-group
                v-model="settlementMethods"
                style="margin: 0 !important"
                class="simpleRadio"
                :disabled="
                  (settlementMethodsSkeleton && checkAmount) ||
                  isDisableRadioBtnsBecauseOfBankAccounts
                "
                column
              >
                <VanSettlementMethodRadioBtn
                  v-for="(element, index) in settlementMethodsArray"
                  :key="element + index"
                  v-bind="element"
                >
                  <template #hint>
                    <span class="font_8">
                      {{ element.wage }} {{ currencyName }}
                    </span>
                  </template>
                </VanSettlementMethodRadioBtn>
              </v-radio-group>

              <!--cancelable switch-->
              <v-switch
                v-if="checkAmount"
                v-model="cancelableSwitch"
                class="van-switch font_31 mt-1 mb-0"
                color="van_green"
                label="امکان لغو تراکنش"
                hide-details="auto"
                :disabled="!settlementMethods || withdrawAbleAmountOverflow"
              />
              <div
                v-if="settlementMethods"
                class="font_2 radius-4 py-2 px-5"
                :class="[cancelableSwitch ? 'van-light-green' : 'van_color11']"
              >
                {{ cancelableBoxText }}
              </div>
            </v-flex>
          </v-layout>

          <!--paymentNumber-->
          <v-layout
            v-if="showPaymentNumberTextfield"
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
            class="mt-8 pa-0 justify-space-between"
          >
            <v-flex class="label">
              شناسه واریز
              <span v-if="paymentNumberIsRequired">*</span>
            </v-flex>
            <v-flex class="flex-column withlabel big">
              <payment-number
                :class="{ 'mb-2': paymentNumberIsRequired }"
                :required="paymentNumberIsRequired"
                @setValue="paymentNumber = $event"
              />
              <template v-if="paymentNumberIsRequired">
                <div class="d-flex align-center mb-2">
                  <v-icon class="ml-1" color="van_color05" size="10px">
                    info_outline
                  </v-icon>
                  <span class="font-size-11 van_color04--text">
                    شناسه واریز باید ۱۵ کاراکتر باشد.
                  </span>
                </div>
                <div
                  class="px-5 py-2 radius-4 van_color11 font-size-12 van_color03--text"
                >
                  وارد کردن شناسه واریز، به این شماره شبا الزامی‌ست.
                </div>
              </template>
            </v-flex>
          </v-layout>

          <!--comment-->
          <template v-if="showCommentOrDescriptionTextarea">
            <van-textarea-with-label
              ref="comment"
              class="mt-3"
              :label-text="'یادداشت محرمانه'"
              :hints="commentHint"
            />

            <!--description-->
            <van-textarea-with-label
              ref="description"
              class="mt-3"
              :label-text="'توضیحات'"
              :hints="descriptionHint"
            />
          </template>
        </div>
      </v-form>
    </GeneralCard>

    <!--modals-->
    <v-dialog
      v-model="ibanDailyLimitModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <detail-daily-settlement-for-iban
        v-if="ibanDailyLimitModal"
        :is-owner="isOwner"
        :daily-settlement-limit="ibanDailyLimit"
        @closeModal="close_modal"
      />
    </v-dialog>

    <!--confirmation modal-->
    <GeneralDialog
      v-if="confirmationModal"
      :active="confirmationModal"
      :logo="checkAmount ? 'Condition.svg' : 'box-gray-past-time.svg'"
      content-class="confirmationMessageDialog"
      :title="
        checkAmount
          ? 'از انجام این تراکنش اطمینان دارید؟'
          : 'آیا این برداشت را در نوبت قرار می‌دهید؟'
      "
      :description="
        checkAmount
          ? null
          : 'با در نوبت قرار گرفتن این تراکنش، پس از بالا رفتن موجودی کیف‌پول، تراکنش انجام می‌شود.'
      "
      :width="360"
      :btn1-loading="confirmLoading"
      btn1-text="انجام شود"
      btn2-text="ویرایش می‌کنم"
      @btn1Clicked="sendForm()"
      @btn2Clicked="confirmationModal = false"
    >
      <template #extra>
        <div class="font_2 mt-5 pa-5 van_color11 w-100 align-center">
          <div
            v-for="(confirmItem, index) in whichFiled"
            :key="confirmItem + index"
          >
            <template v-if="confirmItem.value">
              <hr v-if="index !== 0" class="dashed-divider my-2 w-100" />
              <v-layout align-center>
                <v-flex class="font_8"> {{ confirmItem.label }} </v-flex>
                <v-flex style="text-align: left">
                  {{ confirmItem.value }}
                </v-flex>
                <v-img
                  v-if="
                    confirmItem.value ===
                    (selectedOriginAccount?.account ||
                      selectedOriginAccount?.name)
                  "
                  width="20"
                  height="20"
                  max-height="20"
                  max-width="20"
                  class="mr-1"
                  :src="selectedOriginAccountIcon"
                />
                <v-img
                  v-if="confirmItem.value === selectedIban[0]"
                  width="20"
                  height="20"
                  max-height="20"
                  max-width="20"
                  class="mr-1"
                  :src="
                    require(`@/assets/img/banks/${parseInt(
                      selectedIban[0].slice(4, 7)
                    )}.svg`)
                  "
                />
              </v-layout>
            </template>
          </div>
        </div>
      </template>
    </GeneralDialog>
    <GeneralDialog
      v-if="isWalletAmountNotEnough"
      :active="isWalletAmountNotEnough"
      logo="walletAmountError.svg"
      content-class="confirmationMessageDialog"
      title="عدم موجودی کیف‌پول وندار"
      description="کارمزد برداشت از کیف‌‌پول وندار کسر می‌شود، برای ثبت درخواست برداشت، کیف‌پول وندار را شارژ کنید."
      :width="360"
      btn1-text="شارژ کیف‌پول"
      btn2-text="انصراف"
      @btn1Clicked="openCashIn"
      @btn2Clicked="closeWalletAmountNotEnoughModal"
    >
    </GeneralDialog>

    <v-dialog
      v-model="showQueueDetails"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queue-details @closeModal="close_modal" />
    </v-dialog>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal"
import GeneralCard from "@/components/elements/generalCard"
import IbanBox from "@/components/elements/ibanBox"
import DetailDailySettlementForIban from "@/components/report/modals/addSettlement/detailDailySettlementForIban"
import VanSettlementMethodRadioBtn from "@/components/VANComponents/vanSettlementMethodRadioBtn"
import VanTextareaWithLabel from "@/components/elements/vanTextareaWithLabel"
import PaymentNumber from "@/components/inputs/paymentNumber"
import { v4 as uuidv4 } from "uuid"
import GeneralDialog from "@/components/modals/generalDialog"
import IbanCellingItems from "@/components/elements/ibanCellingItems"
import IbanCelling from "@/components/elements/ibanCelling"
import A2aDailyCardItem from "../elements/a2aDailyCardItem.vue"
import QueueDetails from "@/components/A2A/modals/queueDetails.vue"
import vanModalAnnouncementBar from "../../VANComponents/vanModalAnnouncementBar.vue"
import BankAccountsSelectBoxVue from "../elements/BankAccountsSelectBox.vue"
import currencyConvert from "@/mixins/currencyConvert.js"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "WithdrawalMoney",
  components: {
    QueueDetails,
    IbanCelling,
    IbanCellingItems,
    GeneralDialog,
    PaymentNumber,
    VanTextareaWithLabel,
    VanSettlementMethodRadioBtn,
    DetailDailySettlementForIban,
    IbanBox,
    GeneralCard,
    LeftModal,
    A2aDailyCardItem,
    vanModalAnnouncementBar,
    BankAccountsSelectBoxVue,
  },
  mixins: [currencyConvert],
  data() {
    return {
      selectedIban: null,
      amount: null,
      walletAmount: 0,
      withdrawAbleAmount: 0,
      wage: 0,
      wageObject: {},
      paymentNumber: "",
      ibanDailyLimit: null,
      cellingSkeleton: true,
      isOwner: false,
      ibanDailyLimitModal: false,
      showSettlementMethods: false,
      hasA2aBank: false,
      walletSkeleton: true,
      timer: undefined,
      a2aDailyLimitAmount: null,
      settlementMethods: null,
      settlementMethodsSkeleton: true,
      a2aTimePrediction: null,
      a2aCancelableTime: null,
      achTimePrediction: null,
      achCancelableTime: null,
      cancelableSwitch: false,
      timePredictionData: null,
      trackId: null,
      shaking: false,
      confirmationModal: false,
      confirmLoading: false,
      showCellingBox: true,
      achSkeleton: true,
      selectedBank: null,
      bankIconSkeleton: true,
      showQueueDetails: false,
      amountRules: [
        () =>
          !this.overflowMaxAmount ||
          `<span> حداکثر مبلغ قابل برداشت ${this.maxAmountWithSeparator} ${this.currencyName} است</span>`,
        () => this.checkAmount || this.messageForLackOfAmount,
        (v) =>
          !v || this.ibanLimitationRule || `<span>${this.ibanRuleText}</span>`,
      ],
      commentHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" alt="icon" src="' +
        require("@/assets/img/info.svg") +
        '" >متنی که فقط برای شما قابل مشاهده است</div>',
      descriptionHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" alt="icon" src="' +
        require("@/assets/img/info.svg") +
        '" >این متن در رسید نمایش داده می‌شود</div>',
      ibanIsInBlackList: false,
      selectedOriginAccount: null,
      areOriginAndDestinationSameBank: false,
      isDisableRadioBtnsBecauseOfBankAccounts: false,
      isWalletAmountNotEnough: false,
    }
  },
  computed: {
    queueNotSeen() {
      if (this.$store.state.isUser) return false
      return !this.$store.state.user?.actions?.find(
        (item) => item.action_name === "queued_settlement_onboarding"
      )
    },
    whichFiled() {
      return this.checkAmount
        ? this.confirmationModalFiled
        : this.queueConfirmationModalFiled
    },
    isRial() {
      return this.$store.state.user.currency === "RIAL"
    },
    minAmount() {
      return this.isRial ? 50000 : 5000
    },
    maxAmount() {
      return this.isRial ? 200000000000 : 20000000000
    },
    checkAmount() {
      const wage = this.wage ?? 0
      return this.isSelectedOriginAccountCashWallet
        ? this.amount + wage <=
            this.tomanToCurrency(this.walletDeductibleAmount)
        : this.amount <=
            this.reuseConvertCurrencyForRialAmount(
              this.selectedOriginAccount?.available_balance
            )
    },
    amountInWords() {
      return wordifyfa(this.amount ?? "0")
    },
    ibanLimitationRule() {
      const cashWalletSourceCondition =
        this.isOwner ||
        this.ibanDailyRemainedAmount === -1 ||
        this.amount <= this.rialToCurrency(this.ibanDailyRemainedAmount)

      return this.isSelectedOriginAccountCashWallet
        ? cashWalletSourceCondition
        : true
    },
    noIbanA2aLimitation() {
      return (
        this.isOwner ||
        (this.a2AIbanDailyTotalAmount > 0
          ? this.amount <= this.rialToCurrency(this.a2AIbanDailyRemainedAmount)
          : false)
      )
    },
    ibanDailyRemainedAmount() {
      return this.ibanDailyLimit?.[0]?.remain_amount
    },
    a2AIbanDailyRemainedAmount() {
      return this.ibanDailyLimit?.[0]?.remain_a2a_amount ?? 0
    },
    a2AIbanDailyTotalAmount() {
      return this.ibanDailyLimit?.[0]?.total_a2a_amount ?? 0
    },
    isA2ALimitless() {
      return (
        [this.a2AIbanDailyRemainedAmount, this.a2aDailyLimitAmount].includes(
          -1
        ) || this.isOwner
      )
    },
    minA2AIbanDailyRemainedAmount() {
      return Math.min(this.a2AIbanDailyRemainedAmount, this.a2aDailyLimitAmount)
    },
    totalBankLessThanIbanCelling() {
      return this.a2AIbanDailyRemainedAmount > this.a2aDailyLimitAmount
    },
    ibanRuleText() {
      return "مبلغ وارد شده، بیشتر از سقف کلی این شبا است."
    },
    amountFiled: {
      get() {
        return this.amount
          ? this.$options.filters.thousandSeprator(this.amount)
          : null
      },
      set(newValue) {
        this.amount = this.keepOnlyDigits(newValue)
      },
    },
    disableSubmitBtn() {
      return !(
        this.amountIsValid &&
        !this.confirmLoading &&
        this.settlementMethods &&
        this.selectedIban?.length !== 0 &&
        (!this.paymentNumberIsRequired || this.paymentNumber.length === 15)
      )
    },
    reachMinAmount() {
      return this.amount && this.amount >= this.minAmount
    },
    overflowMaxAmount() {
      return this.maxAmount && this.amount > this.maxAmount
    },
    maxAmountWithSeparator() {
      return this.$options.filters.thousandSeprator(this.maxAmount)
    },
    ibanCellingIsFinished() {
      return this.isSelectedOriginAccountCashWallet
        ? this.ibanDailyLimit && this.ibanDailyRemainedAmount === 0
        : false
    },
    a2aIsActive() {
      return this.$store.state.business?.tools?.A2A?.status === "ACTIVE"
    },
    showAmountFiled() {
      return (
        !this.achSkeleton &&
        this.selectedIban &&
        !this.cellingSkeleton &&
        !this.ibanCellingIsFinished &&
        !this.checkBlackListIban
      )
    },
    checkBlackListIban() {
      return this.isSelectedOriginAccountCashWallet && this.ibanIsInBlackList
    },
    disableA2aMethod() {
      return (
        (this.a2aIsActive && !this.selectedBank?.a2a?.is_healthy) ||
        (!this.isA2ALimitless &&
          (this.minA2AIbanDailyRemainedAmount === 0 ||
            this.withdrawAbleAmountOverflow ||
            this.rialToCurrency(this.minA2AIbanDailyRemainedAmount) <
              this.amount))
      )
    },
    withdrawAbleAmountOverflow() {
      return this.isSelectedOriginAccountCashWallet
        ? this.amount > this.tomanToCurrency(this.withdrawAbleAmount)
        : this.amount >
            this.reuseConvertCurrencyForRialAmount(
              this.selectedOriginAccount?.available_balance
            )
    },
    a2aMethodText() {
      if (!this.noIbanA2aLimitation) {
        return this.a2AIbanDailyTotalAmount > 0
          ? `غیرفعال در تراکنش‌های بالای ${
              this.rialToCurrency(this.a2AIbanDailyTotalAmount) / 1000000
            } میلیون روزانه`
          : "محدودیت تسویه آنی برای کد صنف شما"
      }
      if (this.a2aIsActive && !this.selectedBank?.a2a?.is_healthy)
        return "بانک موقتا اختلال دارد."
      if (!this.isA2ALimitless) {
        if (this.minA2AIbanDailyRemainedAmount === 0)
          return "سقف امروز شبا تمام شده است"
        if (
          this.rialToCurrency(this.minA2AIbanDailyRemainedAmount) < this.amount
        )
          return "مبلغ بیشتر از سقف شبا است"
      }
      return this.a2aTimePrediction
    },
    cancelableBoxText() {
      if (!this.checkAmount && this.isSelectedOriginAccountCashWallet) {
        return "شما تا بالا رفتن موجودی کیف‌پول، می‌توانید درخواست خود را لغو کنید."
      } else {
        if (!this.cancelableSwitch)
          return ` با فعالسازی امکان لغو، شما تا زمان ارسال به بانک، فرصت لغو درخواست خود را خواهید داشت.`

        if (this.withdrawAbleAmountOverflow)
          return `تراکنش شما
        ${this.settleTime}
         انجام خواهد شد و شما تا
         ${this.cancelingTime}
         می توانید درخواست خود را لغو کنید.`

        if (this.cancelableSwitch === true)
          return `با انتخاب امکان لغو، زمان واریز به
        ${this.settleTime}
         تغییر کرد و بعد از ثبت درخواست، تا
         ${this.cancelingTime}
          می‌توانید تراکنش را لغو کنید.`

        return ""
      }
    },
    hintsArray() {
      return [
        {
          showCondition: !this.reachMinAmount,
          iconName: "moneyHint",
          text: `حداقل مبلغ: ${this.$options.filters.thousandSeprator(
            this.minAmount
          )}`,
        },
        {
          showCondition: this.reachMinAmount,
          iconName: "moneyHint",
          text: this.amountInWords,
        },
      ]
    },
    settleTime() {
      return this.settlementMethods === "ACH"
        ? this.achTimePrediction
        : this.a2aTimePrediction
    },
    cancelingTime() {
      return this.settlementMethods === "ACH"
        ? this.achCancelableTime
        : this.a2aCancelableTime
    },
    isInstant() {
      return !(this.withdrawAbleAmountOverflow || this.cancelableSwitch)
    },
    settlementMethodsArray() {
      return [
        {
          value: "A2A",
          show: this.a2aIsActive && this.hasA2aBank,
          disable: this.checkAmount ? this.disableA2aMethod : false,
          label: "تسویه آنی",
          skeleton: this.checkAmount
            ? !this.disableA2aMethod && this.settlementMethodsSkeleton
            : false,
          extraInfo:
            this.checkAmount && !this.noIbanA2aLimitation
              ? this.a2aMethodText
              : null,
          shake: this.settlementMethods === "A2A" && this.shaking,
          wage:
            "کارمزد " +
            this.$options.filters.thousandSeprator(
              this.rialToCurrency(this.wageObject.a2a)
            ),
        },
        {
          value: "ACH",
          show: true,
          disable: false,
          label: "پایا",
          skeleton: this.checkAmount ? this.settlementMethodsSkeleton : false,
          extraInfo: this.checkAmount ? this.achTimePrediction : null,
          shake: this.settlementMethods === "ACH" && this.shaking,
          wage:
            "کارمزد " +
            this.$options.filters.thousandSeprator(
              this.rialToCurrency(this.wageObject.ach)
            ),
        },
      ]
    },
    confirmationModalFiled() {
      return [
        {
          label: "مبلغ",
          value:
            this.$options.filters.thousandSeprator(this.amount) +
            " " +
            this.currencyName,
        },
        {
          label: "حساب مبدا",
          value: this.isSelectedOriginAccountCashWallet
            ? this.selectedOriginAccount.name
            : this.selectedOriginAccount.account,
        },
        {
          label: "دریافت کننده",
          value: this.selectedIban[1],
        },
        {
          label: "مقصد",
          value: this.selectedIban[0],
        },
        {
          label: "زمان واریز",
          value: this.settleTime,
        },
        {
          label: "کارمزد وندار",
          value:
            this.$options.filters.thousandSeprator(this.wage) +
            " " +
            this.currencyName,
        },
        {
          label: "امکان لغو",
          value: this.cancelingTime ? "تا " + this.cancelingTime : null,
        },
      ]
    },
    queueConfirmationModalFiled() {
      return [
        {
          label: "مبلغ",
          value:
            this.$options.filters.thousandSeprator(this.amount) +
            " " +
            this.currencyName,
        },
        {
          label: "حساب مبدا",
          value: this.isSelectedOriginAccountCashWallet
            ? this.selectedOriginAccount.name
            : this.selectedOriginAccount.account,
        },
        {
          label: "دریافت کننده",
          value: this.selectedIban[1],
        },
        {
          label: "مقصد",
          value: this.selectedIban[0],
        },
        {
          label: "کارمزد وندار",
          value:
            this.$options.filters.thousandSeprator(this.wage) +
            " " +
            this.currencyName,
        },
        {
          label: "امکان لغو",
          value: "تا قبل از افزایش موجودی کیف‌پول",
        },
      ]
    },
    showAchSkeleton() {
      return this.achSkeleton
    },
    ibanCellingArray() {
      const hasLimitation =
        !this.isOwner &&
        (this.ibanDailyRemainedAmount ?? false) &&
        this.ibanDailyRemainedAmount !== -1
      return [
        {
          label: "سقف کلی شبا",
          cellingValue: hasLimitation
            ? this.ibanDailyRemainedAmount
            : "نامحدود",
          ibanInfoText: null,
          isValueCurrency: hasLimitation,
          skeleton: this.showAchSkeleton,
        },
        {
          label: "سقف امروز تسویه آنی این شبا",
          cellingValue: this.a2AIbanCellingValue,
          ibanInfoText: this.a2aIbanInfo,
          isValueCurrency: !this.isA2ALimitless,
          skeleton: false,
        },
      ]
    },
    a2aIbanInfo() {
      if (this.isA2ALimitless || !this.totalBankLessThanIbanCelling) return null
      const ibanRemaining = this.numberWithSeparatorCurrency(
        this.rialToCurrency(this.a2AIbanDailyRemainedAmount)
      )
      const bankRemaining = this.numberWithSeparatorCurrency(
        this.rialToCurrency(this.a2aDailyLimitAmount)
      )

      return `با کاهش‌ باقی‌مانده تسویه آنی بانک، سقف این شبا از ${ibanRemaining}  به ${bankRemaining}  تغییر کرده است.`
    },
    a2AIbanCellingValue() {
      return this.hasA2aBank
        ? this.isA2ALimitless
          ? "نامحدود"
          : this.minA2AIbanDailyRemainedAmount
        : null
    },
    amountIsValid() {
      return (
        this.reachMinAmount &&
        !this.overflowMaxAmount &&
        this.ibanLimitationRule &&
        (this.isSelectedOriginAccountCashWallet ? true : this.checkAmount)
      )
    },
    paymentNumberIsRequired() {
      // #ELD-174
      return this.selectedIban[0] === "IR260620000000203443585001"
    },
    showPaymentNumberTextfield() {
      return Boolean(this.showSettlementMethods && this.settlementMethods)
    },
    showCommentOrDescriptionTextarea() {
      return this.paymentNumberIsRequired
        ? this.showPaymentNumberTextfield && this.paymentNumber.length === 15
        : this.showPaymentNumberTextfield
    },
    selectedOriginAccountIcon() {
      const hasIconProperty = Object.prototype.hasOwnProperty.call(
        this.selectedOriginAccount,
        "icon"
      )
      return this.isSelectedOriginAccountCashWallet && hasIconProperty
        ? this.selectedOriginAccount?.icon
        : require(`@/assets/img/banks/${parseInt(
            this.selectedOriginAccount?.iban.slice(4, 7)
          )}.svg`)
    },
    isSelectedOriginAccountCashWallet() {
      return (
        this.selectedOriginAccount &&
        !Object.hasOwnProperty.call(this.selectedOriginAccount, "account") &&
        this.selectedOriginAccount?.name === "کیف‌ پول وندار"
      )
    },
    checkWalletAmountForBankAccountOrigin() {
      // deductible_amount is available amount in wallet
      if (this.isSelectedOriginAccountCashWallet) return true
      else return this.walletDeductibleAmount - this.wage >= 0
    },
    messageForLackOfAmount() {
      return this.isSelectedOriginAccountCashWallet
        ? true
        : `<span>مبلغ وارد شده از موجودی حساب شما بیشتر است</span>`
    },
    isNeedToShowProgress() {
      return this.isSelectedOriginAccountCashWallet
    },
    walletDeductibleAmount() {
      return this.$store.state.walletAmountDetails.walletDeductibleAmount
    },
  },
  watch: {
    ibanDailyLimitModal: function (newValue) {
      if (!newValue) {
        this.$store.commit("changeSecondSettlementModal", false)
      }
    },
    showQueueDetails: function (newValue) {
      if (!newValue) {
        this.$store.commit("changeSecondSettlementModal", false)
      }
    },
    selectedIban(newValue) {
      this.hasA2aBank = false
      this.amount = null

      this.resetData()

      if (newValue) {
        this.cellingSkeleton = true
        this.ibanDailyLimit = null

        this.handleCellingAmounts()
      } else {
        this.achSkeleton = true
      }
    },
    amount(newAmount, oldAmount) {
      this.resetData()
      if (this.isOwner) {
        this.setA2aAsSettlementMethod()
      } else if (
        oldAmount > this.rialToCurrency(this.a2AIbanDailyRemainedAmount) &&
        this.noIbanA2aLimitation
      ) {
        this.setAchAsSettlementMethod()
      } else {
        this.setA2aAsSettlementMethod()
      }

      if (newAmount && this.amountIsValid) {
        this.showSettlementMethods = true
        this.getWage()
        this.refreshSettlementMethods(
          this.toRial(this.convertNumbers2English(newAmount.toString()))
        )
        if (this.withdrawAbleAmountOverflow) this.cancelableSwitch = true
        this.handleEnableOrDisableStateOfRadioButtons()

        this.setWageBasedOnSelectedSettlementMethod(this.settlementMethods)
      } else {
        this.showSettlementMethods = false
        this.wage = 0
      }
    },
    withdrawAbleAmountOverflow: function (newValue) {
      if (newValue) {
        this.cancelableSwitch = true
      }
    },
    cancelableSwitch: function (newValue) {
      if (newValue !== null) {
        if (this.timePredictionData)
          this.setTimePredictions(this.timePredictionData)
        this.shaking = true
        setTimeout(() => (this.shaking = false), 300)
      }
    },
    settlementMethods(newValue) {
      this.setWageBasedOnSelectedSettlementMethod(newValue)
    },
  },
  mounted() {
    this.getSettlementInfo()
  },
  methods: {
    handlePaste(event) {
      this.amountFiled = this.handlePasteEventForNumericValues(event)
    },
    showDetailsForQueue(firstTime = false) {
      this.showQueueDetails = true
      this.$store.commit("changeSecondSettlementModal", true)
      if (firstTime) {
        this.$store.dispatch("saveAction", {
          name: "queued_settlement_onboarding",
        })
      }
    },
    toToman(value) {
      return !value || !this.isRial ? value : Math.round(value / 10)
    },
    toRial(value) {
      return !value || this.isRial ? value : value * 10
    },
    rialToCurrency(value) {
      return !value || this.isRial ? value : Math.round(value / 10)
    },
    tomanToCurrency(value) {
      return !value || !this.isRial ? value : value * 10
    },
    resetData() {
      this.a2aTimePrediction = null
      this.a2aCancelableTime = null
      this.achTimePrediction = null
      this.achCancelableTime = null
      this.settlementMethods = null
      this.cancelableSwitch = null
      this.paymentNumber = ""
      if (this.checkAmount) {
        this.settlementMethodsSkeleton = true
      }
    },
    showIbanDailyLimitModal() {
      this.$store.commit("changeSecondSettlementModal", true)
      this.ibanDailyLimitModal = true
    },
    close_modal() {
      this.$store.commit("changeSecondSettlementModal", false)
      this.showQueueDetails = false
      this.ibanDailyLimitModal = false
    },
    getWage() {
      let data = {
        amount: this.toRial(this.amount),
        iban: this.selectedIban[0],
      }
      this.addSourceAndAccountToPayload(data)
      this.$http
        .post(
          this.$store.getters.apiWithVersionParam("3") +
            "/settlement/prediction/wage",
          data
        )
        .then((response) => {
          this.wageObject = response?.data?.data
          this.setWageBasedOnSelectedSettlementMethod(this.settlementMethods)
        })
        .catch(() => {
          //call snackbar
        })
    },
    getSettlementInfo() {
      servicesRepository(this)
        .settlementServices.httpGetSettlementInfo()
        .then((response) => {
          let responseData = response.data.data
          this.$store.commit("setWalletAmountDetails", responseData)
          this.walletAmount = responseData?.wallet
          let overdraft = responseData.overdraft
          this.withdrawAbleAmount = responseData?.wallet_deductible_amount
          this.deductibleWithOverdraft(overdraft)
          this.walletSkeleton = false
        })
    },
    deductibleWithOverdraft(overdraft) {
      if (overdraft && overdraft?.remaining) {
        this.withdrawAbleAmount = Math.min(
          this.withdrawAbleAmount + overdraft.remaining,
          this.walletAmount
        )
      }
    },
    async getIbanDailyLimit() {
      try {
        const res = await servicesRepository(
          this
        ).settlementServices.httpPostDailySettlementLimit(this.selectedIban[0])
        this.ibanDailyLimit = res?.data
      } finally {
        this.achSkeleton = false
      }
    },
    getIbanInfo() {
      return new Promise((resolve) => {
        this.$http
          .post(this.$store.getters.ibanApiUrlParam + "/check", {
            IBAN: this.selectedIban[0],
          })
          .then((response) => {
            resolve(response.data.data)
            this.ibanIsInBlackList = response.data.data.is_in_black_list
            if (this.checkBlackListIban) {
              this.showCellingBox = false
            }
          })
      })
    },
    handleCellingAmounts() {
      //ACH
      this.getIbanInfo().then((response) => {
        if (
          !this.$store.state.isUser &&
          !response.is_owner &&
          !response.in_whitelist_settlement
        ) {
          this.getIbanDailyLimit()
          this.isOwner = false
        } else {
          this.isOwner = true
          this.achSkeleton = false
        }

        //A2A
        if (this.a2aIsActive)
          this.getA2ABanksLimit().then((response) => {
            this.hasA2aBank = response
            this.bankIconSkeleton = false
            this.cellingSkeleton = false
          })
        else {
          this.bankIconSkeleton = false
          this.cellingSkeleton = false
        }
      })
    },
    getA2ABanksLimit() {
      this.selectedBank = null
      this.bankIconSkeleton = true

      return new Promise((resolve) => {
        this.$http
          .get(
            this.$store.getters.apiWithVersionParam("3") + "/settlement/banks",
            {
              timeout: 0,
            }
          )
          .then((response) => {
            let banks = response?.data?.data
            let bankCode = this.selectedIban[0].slice(4, 7)
            let bank = banks.find(
              (b) => b.code === bankCode && b.a2a.is_active && b.a2a.has_ability
            )

            if (!bank) {
              resolve(false)
              return
            }

            this.a2aDailyLimitAmount = bank?.a2a?.limit?.amount_limit?.remained
            this.selectedBank = bank
            resolve(true)
          })
          .catch(() => {
            //callsnackbar
            return false
          })
      })
    },
    getLocalTime(timestamp) {
      timestamp = parseInt(timestamp) * 1000
      let jMoment = require("moment-jalaali")
      jMoment.loadPersian({ dialect: "persian-modern" })
      return this.jalaliDate(timestamp, "jYYYY/jM/jD - HH:mm")
    },
    setTimePredictions() {
      let a2a = this.timePredictionData["a2a"]?.filter(
        (item) => item.is_instant === this.isInstant
      )
      this.a2aTimePrediction = this.getLocalTime(a2a[0]?.time_prediction)
      this.a2aCancelableTime = this.getLocalTime(a2a[0]?.cancelable_time)

      let ach = this.timePredictionData["ach"]?.filter(
        (item) => item.is_instant === this.isInstant
      )
      this.achTimePrediction = this.getLocalTime(ach[0]?.time_prediction)
      this.achCancelableTime = this.getLocalTime(ach[0]?.cancelable_time)

      this.settlementMethodsSkeleton = false
    },
    refreshSettlementMethods(amount) {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }

      if (this.checkAmount) {
        this.timer = setTimeout(() => {
          this.getSettlementsPrediction(amount)
        }, this.$store.state.delayTimeToTakeAction)
      }
    },
    getSettlementsPrediction(amount) {
      if (!this.amountIsValid) return
      let data = {
        amount: amount,
        iban: this.selectedIban[0],
      }
      this.addSourceAndAccountToPayload(data)

      this.$http
        .post(
          this.$store.getters.apiWithVersionParam("3") +
            "/settlement/prediction/time",
          data
        )
        .then((response) => {
          this.timePredictionData = response?.data?.data
          if (this.timePredictionData) this.setTimePredictions(response.data)
        })
    },
    sendForm() {
      this.trackId = uuidv4()

      if (this.$refs.form.validate()) {
        this.confirmLoading = true

        let storeUrl =
          this.$store.getters.apiWithVersionParam("2.1") + "/settlement/store"

        let data = {
          amount: this.toToman(this.amount),
          iban: this.selectedIban[0],
          payment_number: this.paymentNumber,
          comment: this.$refs.comment.textArea,
          description: this.$refs.description.textArea,
          is_instant: !this.cancelableSwitch,
          type: this.settlementMethods,
        }
        if (this.isSelectedOriginAccountCashWallet) {
          data.source = "WALLET"
        } else {
          data.source = "ACCOUNT"
          data.account = this.selectedOriginAccount.iban
        }

        if (!this.$store.state.isUser) {
          data.track_id = this.trackId
          storeUrl =
            this.$store.getters.apiWithVersionParam("3") + "/settlement/store"
        }

        if (!this.checkAmount && this.isSelectedOriginAccountCashWallet) {
          data.amount = this.toRial(this.amount)
          storeUrl =
            this.$store.getters.apiWithVersionParam("3") + "/settlement/queued"
          data = {
            group_track_id: uuidv4(),
            settlements: [data],
          }
        }

        this.$http
          .post(storeUrl, data)
          .then((response) => {
            if (this.checkAmount) {
              let settlementData =
                response?.data?.data?.settlement[
                  Object.keys(response.data.data.settlement)[0]
                ]

              let text =
                settlementData?.prediction?.extra ??
                settlementData?.prediction?.date +
                  " ساعت " +
                  settlementData?.prediction?.time

              this.$emit("closeModal", {
                status: true,
                amount: this.amount,
                name: this.selectedIban[1],
                sheba: this.selectedIban[0],
                time: text,
                receiptUrl: settlementData?.receipt_url,
                isInQueue: false,
              })
              this.$store.commit("callSuccessSnackbar", "درخواست برداشت ثبت شد")
            } else {
              this.$emit("closeModal", {
                status: true,
                amount: this.amount,
                name: this.selectedIban[1],
                sheba: this.selectedIban[0],
                receiptUrl: null,
                isInQueue: true,
              })
            }
          })
          .catch((error) => {
            const settlementIbanErrorMessage =
              "تسویه به این شماره شبا مجاز نیست. "

            if (
              error?.response?.status === 422 &&
              error?.response?.data?.errors?.user_name
            ) {
              this.$store.commit(
                "callsnackbar",
                error.response.data.errors.user_name[0]
              )
            } else if (error === settlementIbanErrorMessage) {
              this.$emit("settlement-iban-error", settlementIbanErrorMessage)
            } else {
              this.$emit("errorHappened")
            }
          })
      }
    },
    setSelectedIban(event) {
      const hasOriginAccountIban = Object.prototype.hasOwnProperty.call(
        this.selectedOriginAccount,
        "iban"
      )

      if (hasOriginAccountIban && event) {
        this.areOriginAndDestinationSameBank =
          this.selectedOriginAccount.iban.slice(4, 7) === event[0].slice(4, 7)
      }

      this.selectedIban = event
      if (!event) {
        this.bankIconSkeleton = true
      }
    },
    numberWithSeparatorCurrency(amountValue) {
      return `${this.$options.filters.thousandSeprator(amountValue)} ${
        this.currencyName
      }`
    },
    setSelectedOriginAccount(originAccount) {
      this.selectedIban = null
      this.selectedOriginAccount = originAccount
    },
    handleEnableOrDisableStateOfRadioButtons() {
      if (!this.isSelectedOriginAccountCashWallet) {
        if (this.areOriginAndDestinationSameBank) {
          this.setA2aAsSettlementMethod()
        } else {
          this.setAchAsSettlementMethod()
        }
        this.isDisableRadioBtnsBecauseOfBankAccounts = true
      } else {
        this.isDisableRadioBtnsBecauseOfBankAccounts = false
        if (!this.noIbanA2aLimitation) {
          this.setAchAsSettlementMethod()
          this.isDisableRadioBtnsBecauseOfBankAccounts = true
        }
      }
    },
    openModalForNotEnoughWalletAmount() {
      if (this.amount && !this.checkWalletAmountForBankAccountOrigin) {
        this.openWalletAmountNotEnoughModal()
      } else {
        this.closeWalletAmountNotEnoughModal()
        this.confirmationModal = true
      }
    },
    openCashIn() {
      this.$emit("open-cashIn")
      this.closeWalletAmountNotEnoughModal()
    },
    closeWalletAmountNotEnoughModal() {
      this.isWalletAmountNotEnough = false
    },
    openWalletAmountNotEnoughModal() {
      this.isWalletAmountNotEnough = true
    },
    openConfirmationModal() {
      this.openModalForNotEnoughWalletAmount()
    },
    setA2aAsSettlementMethod() {
      this.settlementMethods = "A2A"
    },
    setAchAsSettlementMethod() {
      this.settlementMethods = "ACH"
    },
    setWageBasedOnSelectedSettlementMethod(selectedSettlementMethod) {
      if (selectedSettlementMethod === "A2A") {
        this.wage = this.rialToCurrency(this.wageObject.a2a)
      } else if (selectedSettlementMethod === "ACH") {
        this.wage = this.rialToCurrency(this.wageObject.ach)
      }
    },
    addSourceAndAccountToPayload(data) {
      if (this.isSelectedOriginAccountCashWallet) {
        data.source = "WALLET"
        data.account = ""
      } else {
        data.source = "ACCOUNT"
        data.account = this.selectedOriginAccount.iban
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.celling-limits {
  border-radius: 0 0 4px 4px;
}

.dashed-divider {
  border: 1px dashed #bbbbbb;
}

.v-input {
  &--switch {
    .v-messages {
      display: none;
    }
  }
}

.priceBoxes {
  gap: 20px;
}

@media screen and (max-width: 320px) {
  .logo-display-320 {
    display: none;
  }
}

@media screen and (min-width: 321px) {
  .logo-display-320 {
    display: block;
  }
}
</style>
