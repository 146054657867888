<script>
export default {
  name: "FeeWalletStatic",
}
</script>

<template>
  <div class="px-3 py-2">
    <v-layout column>
      <v-flex class="font_2 mb-0 mt-5">
        <div>
          با استفاده از درگاه پرداخت وندار، می‌توانید کیف پول کارمزدی خود را در
          این قسمت شارژ نمایید و از این پس، برای پرداخت‌ کارمزدها، از آن استفاده
          کنید.
        </div>
        <p class="statement-style">
          توجه نمایید که این کیف پول <b>غیرقابل برداشت</b> است و تنها می‌توان از
          آن برای پرداخت کارمزدها استفاده نمود.
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped lang="scss">
.statement-style {
  background-color: $van_color_yellow1;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0;
}
</style>
