<script>
import servicesRepository from "@/services/servicesRepository"
import InputContainer from "@/components/elements/inputContainer.vue"

export default {
  name: "EditSettingIps",
  components: { InputContainer },
  props: {
    ipsListProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ipsList: [],
      ipValidationPattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      ipsHint: `<div class="d-flex align-center">
        <img style="max-width:10px;margin-left:5px" src=${require("@/assets/img/info.svg")} >
        بعد از وارد کردن هر آی‌‌پی، دکمه «اینتر» را بزنید.
        </div>`,
      ipsRule: [
        (v) => !!v || "<span> وارد کردن آی‌ پی‌ الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.ipValidationPattern)) ||
          "<span> فرمت صحیح آی‌ پی‌ را وارد کنید </span>",
        (v) =>
          (!!v && v.length <= 10) ||
          "<span> حداکثر تعداد مجاز آی‌پی 10 عدد می‌باشد </span>",
      ],
      isLoadingBtn: false,
      isFormValid: true,
    }
  },
  computed: {
    ipsListComputed: {
      get() {
        return this.ipsList
      },
      set(val) {
        this.isFormValid = this.$refs.form.validate()
        if (val?.length <= 10) {
          if (val?.length > 0) {
            for (let i = 0; i < val.length; i++) {
              val[i] = this.convertNumbers2English(val[i])
            }
          }
          this.ipsList = Array.from(new Set(val))
        }
      },
    },
  },
  mounted() {
    this.ipsList = [...this.ipsListProp]
  },
  methods: {
    checkFormat(items, selectedpattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedpattern && !selectedpattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }

      return doesAllItemsMatch
    },
    checkIps(event) {
      if (
        !/(^[0-9۰-۹٫]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    async editIps() {
      try {
        this.isLoadingBtn = true
        await servicesRepository(this).businessServices.httpUpdateBusinessIps({
          ips: this.ipsList,
        })
      } finally {
        this.isLoadingBtn = false
        this.$emit("closeModal")
      }
    },
  },
}
</script>

<template>
  <v-form ref="form" lazy-validation>
    <v-card class="headerBox">
      <v-toolbar flat height="60" class="fixed_toolbar">
        <v-btn icon @click.native="$emit('closeModal')">
          <v-icon color="van_color03" size="21"> close </v-icon>
        </v-btn>
        <v-toolbar-title class="pr-0"> لیست آی‌پی‌های مجاز</v-toolbar-title>
        <v-spacer />
        <v-btn
          text
          dark
          class="btn_small_normal"
          :loading="isLoadingBtn"
          :disabled="!isFormValid"
          @click="editIps"
        >
          ذخیره
        </v-btn>
      </v-toolbar>
      <v-layout column>
        <v-flex>
          <v-layout column class="rows pa-10">
            <InputContainer label="آی‌پی‌های شما" :optional="false">
              <template>
                <v-flex class="withlabel big comboboxSmallchip">
                  <v-combobox
                    ref="ips"
                    v-model="ipsListComputed"
                    hide-selected
                    multiple
                    small-chips
                    solo
                    flat
                    outlined
                    deletable-chips
                    :rules="ipsRule"
                    :hint="ipsHint"
                    @keypress="checkIps($event)"
                  >
                    <template #message="message">
                      <div v-html="message.message"></div>
                    </template>
                  </v-combobox>
                </v-flex>
              </template>
            </InputContainer>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-form>
</template>

<style scoped lang="scss"></style>
