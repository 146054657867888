<template>
  <div class="showModalBoxesContainer">
    <v-flex>
      <v-layout
        height="100%"
        align-stretch
        justify-center
        :class="setBackground"
        :column="$vuetify.breakpoint.xsOnly"
      >
        <MiniBoxSingleCard
          :status="transactionInfo.item.status"
          :amount="transactionInfo.item.amount"
          :wage="transactionInfo.item.wage"
          :shaparak-wage="transactionInfo.item.shaparakWage"
          :channel="transactionInfo.item.channel"
        />
        <v-flex xs6>
          <v-layout align-center justify-center column>
            <MiniBoxCard
              v-for="(element, index) in miniBoxList"
              :key="transactionInfo.item.value + '' + index"
              :label="element.label"
              :value="element.value"
              :contain-copy="element.containCopy"
              :shake-it="element.shakeIt"
            />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
import MiniBoxSingleCard from "@/components/report/elements/show/cards/miniBoxSingleCard.vue"
import MiniBoxCard from "@/components/report/elements/show/cards/miniBoxCard.vue"

export default {
  components: { MiniBoxSingleCard, MiniBoxCard },
  props: {
    transactionInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setBackground() {
      const condition =
        this.transactionInfo.item.status === -1 ||
        this.transactionInfo.item.status === -3 ||
        this.transactionInfo.item.status === -4 ||
        this.transactionInfo.item.status === -6 ||
        this.transactionInfo.item.status === "FAILED" ||
        this.transactionInfo.item.status === -8
          ? "redBack"
          : this.transactionInfo.item.status === 3 ||
            this.transactionInfo.item.status === -2 ||
            this.transactionInfo.item.status === -9
          ? "orangeBack"
          : ""

      return condition
    },
    miniBoxList() {
      var items = []

      items.push({
        label: "شناسه یکتا",
        value: this.transactionInfo.item.id,
        containCopy: "true",
      })

      const labelDate = "تاریخ ایجاد"

      const valueDate = this.dateTime(this.transactionInfo.item.created_at)

      items.push({
        label: labelDate,
        value: valueDate,
      })

      if (
        (this.transactionInfo.item.status == 2 ||
          this.transactionInfo.item.status == -2) &&
        !this.isTransactionRefund &&
        this.transactionInfo.time_prediction.settlement_done_time_prediction !=
          null
      ) {
        items.push({
          label: "زمان تخمینی واریز",
          value: this.doneTimePrediction,
          shakeIt:
            this.transactionInfo?.time_prediction
              ?.has_change_done_time_prediction ||
            this.transactionInfo.time_prediction?.is_admin_check_status,
        })
      } else if (
        this.transactionInfo.item.effective_at_jalali != null &&
        this.transactionInfo.item.effective_at_jalali !=
          this.transactionInfo.item.created_at
      ) {
        items.push({
          label: "آخرین به‌روزرسانی",
          value: this.dateTime(this.transactionInfo.item.effective_at_jalali),
        })
      }

      return items
    },
    doneTimePrediction() {
      if (
        this.transactionInfo.time_prediction?.is_admin_check_status ||
        this.transactionInfo.time_prediction?.is_after_time_prediction
      )
        return "در حال محاسبه"
      else {
        return this.dateTime(
          this.transactionInfo.time_prediction.settlement_done_time_prediction
        )
      }
    },
  },
  methods: {
    dateTime(date) {
      const moment = require("moment-jalaali")
      const currentDate = moment(date, "HH:mm:ss - jYYYY/jM/jD")
      return this.jalaliDate(currentDate, "jYYYY/jM/jD - HH:mm")
    },
  },
}
</script>
<style lang="scss" scoped>
.showModalBoxesContainer {
  .miniBox {
    background-color: rgba(76, 159, 136, 0.1);
    border-radius: 4px;
    padding: 23px;
    margin: 5px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }

    &.rightBold {
      margin-left: 10px;
    }
    > .layout {
      height: 100%;
    }
  }
  .redBack {
    .miniBox {
      background-color: rgba(212, 77, 66, 0.2);
    }
  }
  .orangeBack {
    .miniBox {
      background-color: rgba(249, 211, 88, 0.2);
    }
  }
  .grayBack {
    .miniBox {
      background-color: $van_color08;
    }
  }
}
</style>
