<script>
import servicesRepository from "@/services/servicesRepository"
import EditSettingIps from "@/components/modals/EditSettingIps.vue"

export default {
  name: "SettingIps",
  components: { EditSettingIps },
  data() {
    return {
      ipsList: [],
      isOpenEdit: false,
    }
  },
  mounted() {
    this.getIpsList()
  },
  methods: {
    openEditMode() {
      this.isOpenEdit = true
      this.$store.commit("callEditFormModal", true)
    },
    closeEditMode() {
      this.isOpenEdit = false
      this.$store.commit("callEditFormModal", false)
      this.getIpsList()
    },
    async getIpsList() {
      const response = await servicesRepository(
        this
      ).businessServices.httpGetBusinessIps()
      this.ipsList = response.data.data.ips
    },
  },
}
</script>

<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> لیست آی‌پی‌های مجاز</v-toolbar-title>
      <v-spacer />
      <v-btn text dark class="btn_small_normal" @click="openEditMode">
        ویرایش
      </v-btn>
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-layout class="pa-10">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="label">آی‌پی‌های شما</span>
            </v-col>
            <v-col cols="12" sm="9">
              <v-row dense>
                <v-col
                  v-for="(ip, i) in ipsList"
                  :key="i"
                  cols="6"
                  class="pa-0"
                >
                  <span class="font-12-no-333-20">{{ ip }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="isOpenEdit"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      @click:outside="closeEditMode"
    >
      <EditSettingIps
        v-if="isOpenEdit"
        :ips-list-prop="ipsList"
        @closeModal="closeEditMode"
      ></EditSettingIps>
    </v-dialog>
  </v-card>
</template>

<style scoped lang="scss"></style>
