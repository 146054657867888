export const channels = {
  methods: {
    doseChannelMatch(expectedChannelName, channelName) {
      const channels = {
        direct_debit: ["پرداخت خودکار", "پرداخت از حساب"],
        credit: ["پرداخت اعتباری"],
        refund: ["بازگشت وجه"],
        settlement: ["تسویه حساب"],
        cancel_revised_transaction: ["کنسلی تسویه"],
        cash_in: ["واریز بانکی"],
        cash_in_by_code: ["واریز با شناسه"],
        revised: ["اصلاحیه واریز", "بازگشت تسویه حساب شاپرک"],
        request_money: ["فرم دریافت پول"],
        return_revised_transaction: ["برگشت داده شده"],
        transaction_revised: ["واریز اصلاح شده"],
        ipg: ["پرداخت اینترنتی", "پرداخت یکپارچه | درگاه پرداخت"],
        p2p: ["انتقال وجه داخلی"],
        vv_ipg: ["هزینه درگاه اختصاصی"],
        pos: ["تراکنش پایانه فروشگاهی"],
        subscription_returned: ["SUBSCRIPTION_RETURNED"],
        subscription_failed_transaction_fee_in_progress: [
          "کارمزد عدم کفایت موجودی پرداخت خودکار",
        ],
        pbv: ["پرداخت با وندار"],
        bnpl_tara: ["حساب اعتباری تارا"],
        settlement_shaparak: ["تسویه حساب شاپرک"],
        dedicated_channel: ["حساب اختصاصی"],
        fee_wallet: ["FEE"],
      }
      return channels[expectedChannelName].includes(channelName)
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key].includes(value))
    },
    statusChannels(obj) {
      switch (obj.status) {
        // برداشت ها
        case 2:
          return this.getStatusTwo(obj)
        case -5:
          return "settlement_p2p_successful"
        case -2:
          if (obj.isRefund) return "settlement_refund_pending"
          if (obj.channel === "تسویه حساب شاپرک")
            return "settlement_shaparak_pending"
          return "settlement_tashim_pending"
        case -4:
          return "settlement_tashim_returned"
        case -6:
          return "settlement_tashim_refund_returned"
        case -3:
          if (obj.isRefund) return "settlement_refund_returned"
          return "settlement_tashim_unsuccessful"
        case -8:
          return "settlement_shaparak_revised"
        case 9:
          if (obj.channel === "کارمزد عدم کفایت موجودی پرداخت خودکار")
            return "subscription_failed_transaction_fee_succeed"
          return "settlement_vandar_fee"

        // واریزها
        case 1:
          if (obj.channel === "تسویه حساب شاپرک") {
            if (obj.shaparakSuccess) {
              return "settlement_shaparak_successful"
            } else {
              return "settlement_shaparak_approved"
            }
          } else if (obj.channel === "بازگشت تسویه حساب شاپرک") {
            return "transaction_shaparak_revised_successful"
          }
          return this.getStatusOne(obj.channel)
        case 6:
          return "transaction_directDebit_successful"
        case 7:
          return "transaction_pbv_successful"
        case 5:
          return "transaction_p2p_successful"
        case 4:
          return "transaction_ipg_transactionRevised_successful"
        case -1:
          return this.getStatusMinesOne(obj.channel)
        case 3:
          if (this.doseChannelMatch("request_money", obj.channel))
            return "transaction_requestMoney_pending"
          if (this.doseChannelMatch("pbv", obj.channel))
            return "transaction_pbv_pending"
          if (this.doseChannelMatch("ipg", obj.channel))
            return "transaction_ipg_pending"
          if (this.doseChannelMatch("fee_wallet", obj.channel))
            return "transaction_fee_wallet_pending"
          if (this.doseChannelMatch("bnpl_tara", obj.channel))
            return "transaction_pbv_pending"
          if (this.doseChannelMatch("credit", obj.channel))
            return "transaction_credit_pending"
          break
        case -9:
          if (
            this.doseChannelMatch(
              "subscription_failed_transaction_fee_in_progress",
              obj.channel
            )
          )
            return "subscription_failed_transaction_fee_in_progress"
        case 10:
          return "transaction_vandar_fee_reverse"
        //settlement shaparak
        case "FAILED":
          return "settlement_shaparak_failed"
      }
    },
    getStatusOne(channel) {
      if (this.doseChannelMatch("cancel_revised_transaction", channel))
        return "transaction_cancelRevised"
      if (this.doseChannelMatch("pos", channel))
        return "transaction_pos_successful"
      if (this.doseChannelMatch("request_money", channel))
        return "transaction_requestMoney_successful"
      if (this.doseChannelMatch("cash_in", channel))
        return "transaction_cashIn_successful"
      if (this.doseChannelMatch("cash_in_by_code", channel))
        return "transaction_cashInByCode_successful"
      if (this.doseChannelMatch("ipg", channel))
        return "transaction_ipg_successful"
      if (this.doseChannelMatch("revised", channel))
        return "transaction_revised_successful"
      if (this.doseChannelMatch("return_revised_transaction", channel))
        return "transaction_correction"
      if (this.doseChannelMatch("return_revised_transaction", channel))
        return "transaction_correction"
      if (this.doseChannelMatch("credit", channel))
        return "transaction_credit_successful"
      if (this.doseChannelMatch("dedicated_channel", channel)) {
        return "transaction_dedicatedChannel_successful"
      }
      if (this.doseChannelMatch("fee_wallet", channel)) {
        return "transaction_fee_wallet_successful"
      }
    },
    getStatusTwo(obj) {
      if (this.doseChannelMatch("subscription_returned", obj.channel))
        return "settlement_subscriptionReturned_successful"
      if (obj.isRefund) return "settlement_refund_successful"
      if (obj.settlementPort === "2")
        return "settlement_tashim_successful_port2"
      if (obj.isFinallySettlement) return "settlement_tashim_successful_final"
      if (obj.isAfterTimePrediction) return "settlement_tashim_successful_port1"
      return "settlement_tashim_successful_first"
    },
    getStatusMinesOne(channel) {
      if (this.doseChannelMatch("direct_debit", channel))
        return "transaction_directDebit_unsuccessful"
      if (this.doseChannelMatch("request_money", channel))
        return "transaction_requestMoney_unsuccessful"
      if (this.doseChannelMatch("ipg", channel))
        return "transaction_ipg_unsuccessful"
      if (this.doseChannelMatch("cash_in", channel))
        return "transaction_cashIn_unsuccessful"
      if (this.doseChannelMatch("pbv", channel))
        return "transaction_pbv_unsuccessful"
      if (this.doseChannelMatch("bnpl_tara", channel))
        return "transaction_pbv_unsuccessful"
      if (this.doseChannelMatch("credit", channel))
        return "transaction_credit_unsuccessful"
      if (this.doseChannelMatch("fee_wallet", channel))
        return "transaction_fee_wallet_unsuccessful"
    },
  },
  computed: {
    transactionsTitles() {
      return {
        "برداشت در صف بانک": ["settlement_tashim_pending"],
        "بازگشت وجه در انتظار": ["settlement_refund_pending"],
        "بازگشت وجه ناموفق": ["settlement_refund_returned"],
        "برداشت ناموفق": [
          "settlement_tashim_unsuccessful",
          "settlement_shaparak_failed",
          "settlement_shaparak_revised",
        ],
        "واریز اصلاح شده": ["transaction_ipg_transactionRevised_successful"],
        "انتقال وجه": [
          "settlement_p2p_successful",
          "transaction_p2p_successful",
        ],
        "برداشت موفق": [
          "settlement_tashim_successful_port1",
          "settlement_tashim_successful_port2",
          "settlement_tashim_successful_first",
          "settlement_tashim_successful_final",
          "settlement_refund_successful",
          "settlement_subscriptionReturned_successful",
          "settlement_shaparak_successful",
          "settlement_shaparak_approved",
          "subscription_failed_transaction_fee_succeed",
        ],
        "برداشت برگشتی": [
          "settlement_tashim_returned",
          "settlement_tashim_refund_returned",
        ],
        "واریز موفق": [
          "transaction_revised_successful",
          "transaction_cancelRevised",
          "transaction_directDebit_successful",
          "transaction_pos_successful",
          "transaction_requestMoney_successful",
          "transaction_cashIn_successful",
          "transaction_ipg_successful",
          "transaction_fee_wallet_successful",
          "transaction_correction",
          "transaction_pbv_successful",
          "transaction_credit_successful",
          "transaction_cashInByCode_successful",
          "transaction_shaparak_revised_successful",
          "transaction_dedicatedChannel_successful",
        ],
        "واریز ناموفق": [
          "transaction_directDebit_unsuccessful",
          "transaction_requestMoney_unsuccessful",
          "transaction_ipg_unsuccessful",
          "transaction_fee_wallet_unsuccessful",
          "transaction_cashIn_unsuccessful",
          "transaction_pbv_unsuccessful",
          "transaction_credit_unsuccessful",
        ],
        "واریز در صفحه پرداخت": [
          "transaction_requestMoney_pending",
          "transaction_ipg_pending",
          "transaction_fee_wallet_pending",
          "transaction_credit_pending",
        ],
        "درخواست بازگشت وجه": ["addRefundModal"], //this
        "در صفحه پرداخت": ["transaction_pbv_pending"], //this is for addRefund modal
        // is for addRefund modal
        "برداشت در انتظار تایید شاپرک": ["settlement_shaparak_pending"],

        //vandar fee
        "کارمزد وندار": ["settlement_vandar_fee"],

        "در صف برداشت از کیف پول": [
          "subscription_failed_transaction_fee_in_progress",
        ],
      }
    },
  },
}
