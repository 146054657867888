<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import PriceInput from "@/components/BankInvoice/PriceInput.vue"

export default {
  name: "FeeWalletPayment",
  components: { PriceInput, GeneralCard },
  data() {
    return {
      priceObj: {
        price: "",
        formattedPrice: "",
      },
      isDisabled: true,
      hasLimitationError: false,
    }
  },
  methods: {
    setPriceInput(val) {
      this.checkHasLimitationError(val)
      this.priceObj = { ...val }

      if (val.price) {
        this.isDisabled = this.hasLimitationError
      } else this.isDisabled = true
    },
    checkHasLimitationError(value) {
      return this.$store.state.user.currency === "TOMAN"
        ? (this.hasLimitationError =
            value.price &&
            (Number(value.price) < 10000 || Number(value.price) > 200000000))
        : (this.hasLimitationError =
            value.price &&
            (Number(value.price) < 100000 || Number(value.price) > 2000000000))
    },
    goToPayment() {
      const amount =
        this.$store.state.user.currency === "TOMAN"
          ? Number(this.priceObj.price) * 10
          : Number(this.priceObj.price)

      window.location = `${this.$store.state.pay_base_url}/business/${this.$store.state.business.business_name}/fee/request?amount=${amount}&mobile_number=${this.$store.state.user.mobile}`
    },
  },
}
</script>

<template>
  <GeneralCard>
    <div class="px-3 py-2 d-flex flex-column">
      <PriceInput
        title="مبلغ"
        :is-hidden-details="true"
        @send-up-price="setPriceInput"
      />
      <v-row v-show="hasLimitationError" dense class="my-0">
        <v-col cols="3" class="pa-0" />
        <v-col cols="9" class="pa-0">
          <template v-if="$store.state.user.currency === 'TOMAN'">
            <span v-if="priceObj.price < 10000" class="font-10-no-red-15">
              حداقل مبلغ واریز 10,000 تومان است
            </span>
            <span
              v-else-if="priceObj.price > 200000000"
              class="font-10-no-red-15"
            >
              حداکثر مبلغ واریز 200,000,000 تومان است
            </span>
          </template>
          <template v-else>
            <span v-if="priceObj.price < 100000" class="font-10-no-red-15">
              حداقل مبلغ واریز 100,000 ریال است
            </span>
            <span
              v-else-if="priceObj.price > 2000000000"
              class="font-10-no-red-15"
            >
              حداکثر مبلغ واریز 2,000,000,000 ریال است
            </span>
          </template>
        </v-col>
      </v-row>

      <div class="align-self-center w-100">
        <v-btn
          class="mt-6 mx-0 btn_small_normal w-100"
          :disabled="isDisabled"
          dark
          text
          @click="goToPayment"
        >
          اتصال به درگاه پرداخت
        </v-btn>
      </div>
    </div>
  </GeneralCard>
</template>

<style scoped lang="scss"></style>
