<template>
  <tr
    :class="[
      getKeyByValue(rowClass, trans.status),
      $vuetify.breakpoint.smAndDown ? 'pa-5 pr-10 d-flex flex-wrap' : '',
    ]"
    :style="$vuetify.breakpoint.smAndDown ? 'height: auto !important;' : ''"
    @click="showDetail(trans)"
  >
    <!-- 1 -->
    <td
      :class="{ 'w-100 d-block  mr-3 pa-0': $vuetify.breakpoint.smAndDown }"
      class="text-center"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'order:1;position: absolute; height: calc(100% - 40px) !important; right: 0;  width: 30px;'
          : ''
      "
    >
      <div
        :class="{
          'd-flex align-center pa-0 justify-center':
            $vuetify.breakpoint.smAndDown,
        }"
        :style="
          $vuetify.breakpoint.smAndDown
            ? '  width: 100% !important; height: 100% !important;'
            : ''
        "
      >
        {{ trans.rowNumber }}
      </div>
    </td>

    <!-- 2 -->
    <td
      class="text-right"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'order:2; height: unset !important;'
          : ''
      "
      :class="{
        'mb-5 w-100 d-block': $vuetify.breakpoint.smAndDown,
        'padding-right-0': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div style="display: flex; align-items: center">
        <img
          v-if="[1, -1, 3, 5, 6, 4, 7, 10].includes(trans.status)"
          width="16"
          height="14"
          class="my-0"
          :class="{
            'ml-4': $vuetify.breakpoint.smAndDown,
            'mr-2 ml-6': $vuetify.breakpoint.mdAndUp,
          }"
          :src="require('@/assets/img/Ascending.svg')"
        />
        <img
          v-else-if="
            [2, -3, -2, -4, -5, -6, 'FAILED', -8, 9, -9].includes(trans.status)
          "
          width="16"
          height="14"
          class="my-0"
          :class="{
            'ml-4': $vuetify.breakpoint.smAndDown,
            'mr-2 ml-6': $vuetify.breakpoint.mdAndUp,
          }"
          :src="require('@/assets/img/Descending.svg')"
        />
        <span v-if="Object.keys(statusDescriptionResult).length > 0"
          ><span
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'font-size:14px;font-weight:500;'
                : null
            "
            >{{ statusDescriptionResult.title }}</span
          >
          <div
            v-if="
              statusDescriptionResult.subTitle ||
              statusDescriptionResult.subValue
            "
            class="font_8"
          >
            <span v-if="statusDescriptionResult.subTitle">{{
              statusDescriptionResult.subTitle
            }}</span>
            <template
              v-if="
                statusDescriptionResult.subValue &&
                statusDescriptionResult.type === 'currency'
              "
            >
              {{ statusDescriptionResult.subValue | currency }}
              {{ currencyName }}
            </template>
          </div>
        </span>
      </div>
    </td>

    <!-- 3 -->
    <td
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'order:4; height: unset !important;'
          : ''
      "
      class="text-right"
      :class="{ 'mb-4  w-100 d-block': $vuetify.breakpoint.smAndDown }"
    >
      {{ dateTime(trans.effective_at_jalali) }}
    </td>

    <!-- 4 -->
    <!-- succeed 1 AND failed -1 -->
    <td
      :style="
        $vuetify.breakpoint.smOnly
          ? 'position: absolute; right: 40%; width: fit-content;    top: 43%;'
          : $vuetify.breakpoint.smAndDown
          ? 'order:5; height: unset !important;'
          : ''
      "
      :class="{ 'd-flex  w-100': $vuetify.breakpoint.smAndDown }"
      class="text-right tdes"
    >
      <hr
        class="my-0 mx-5 v-divider v-divider--vertical theme--light"
        :class="{ 'd-none': !$vuetify.breakpoint.smOnly }"
      />
      <div>
        <div class="d-flex align-center">
          <img
            v-if="trans?.is_shaparak_port"
            class="pl-2"
            :src="require('@/assets/img/newIcon/shaparak.svg')"
          />
          <span class="tdesrow">
            <v-tooltip
              v-if="!reuseIsMobile && transactionInfo()"
              top
              content-class="tableTooltip"
            >
              <template #activator="{ on }">
                <span
                  v-if="transactionInfo()"
                  style="display: inline-block"
                  v-on="on"
                >
                  {{ transactionInfo() }}
                </span>
              </template>
              <span v-if="transactionInfo()" style="display: inline-block">
                {{ transactionInfo() }}
              </span>
            </v-tooltip>
            <span
              v-if="reuseIsMobile && transactionInfo()"
              style="display: inline-block"
            >
              {{ transactionInfo() }}
            </span>
          </span>

          <v-tooltip
            v-if="trans.comments != null && trans.comments.length !== 0"
            top
            content-class="tableTooltip"
          >
            <template #activator="{ on }">
              <span class="tsvg" v-on="on">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path fill="none" class="a" d="M20,0H0V20H20Z" />
                  <path
                    fill="#333"
                    class="b"
                    d="M3.6,2H16.4A1.6,1.6,0,0,1,18,3.6V18l-3.2-3.2H3.6A1.6,1.6,0,0,1,2,13.2V3.6A1.6,1.6,0,0,1,3.6,2Zm0,11.2H15.464l.936.936V3.6H3.6ZM14,7.6H12.4V9.2H14Zm-6.4,0H6V9.2H7.6Zm3.2,0H9.2V9.2h1.6Z"
                  />
                </svg>
              </span>
            </template>
            <span style="font-size: 12px">{{
              trans.comments[trans.comments.length - 1].comments
            }}</span>
          </v-tooltip>
        </div>

        <!-- row 2 -->
        <div
          v-if="transactionInfoSubLine"
          class="font_8"
          style="padding-top: 2px"
        >
          <div
            v-if="
              transactionInfoSubLine.type ===
              'transaction_cashInByCode_successful'
            "
          >
            <span class="van_red--text">{{ transactionInfoSubLine.text }}</span>
            <img
              class="mr-1 mt-1"
              :src="require('@/assets/img/' + transactionInfoSubLine.icon)"
            />
          </div>

          <span v-else-if="transactionInfoSubLine.text">{{
            transactionInfoSubLine.text
          }}</span>
        </div>
        <!-- row 2 -->
      </div>
    </td>

    <!-- 5 -->
    <td
      v-if="trans.amount || trans.amount === 0"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'font-size:14px;font-weight:500; position:absolute; left:0;width:fit-content'
          : null
      "
      :class="{ 'w-100 d-block': $vuetify.breakpoint.smAndDown }"
      class="text-right"
    >
      <span dir="ltr">
        {{ transactionAmount | currency }}
      </span>

      <span v-if="$vuetify.breakpoint.smAndDown">{{ currencyName }}</span>
    </td>
    <td v-else class="text-right">--</td>

    <!-- 6 -->
    <td
      v-if="trans.is_wallet_affected"
      :class="{ 'w-100 d-block': $vuetify.breakpoint.smAndDown }"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'order:3 ; height: unset !important;'
          : ''
      "
      class="text-right"
      dir="ltr"
    >
      <span v-if="$vuetify.breakpoint.smAndDown"> مانده بعد از واریز</span>
      {{ trans.wallet | currency }}
      <span v-if="$vuetify.breakpoint.smAndDown">
        {{ currencyName }}
      </span>
    </td>
    <td
      v-else
      :class="{ 'w-100 d-block': $vuetify.breakpoint.smAndDown }"
      class="text-right"
    >
      بدون تغییر
    </td>

    <!-- hidden part -->
    <div
      class="hiddentd"
      :class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
      @mouseover="mouseIsOnBtn = true"
      @mouseleave="mouseIsOnBtn = false"
    >
      <v-btn
        v-if="isShowCancelSettlementButton"
        ref="btn"
        text
        outlined
        class="btn_small_normal ml-0"
        @click="deleteSettlement = true"
      >
        لغو برداشت
      </v-btn>
      <v-btn
        ref="btn"
        text
        dark
        :class="'btn_small_normal tblCpy' + trans.id"
        @click="shareLink()"
      >
        <v-icon color="#fff" size="15" class="pl-2"> content_copy</v-icon>
        کپی شناسه یکتا
      </v-btn>
      <v-tooltip v-model="tooltip_show" top>
        <template #activator="{ on }">
          <v-btn
            style="
              position: absolute;
              opacity: 0 !important;
              width: 1px;
              margin: 0px;
              padding: 0px;
              z-index: -1;
              left: 78px;
            "
            v-on="on"
          />
        </template>
        <span>کپی شد</span>
      </v-tooltip>
      <input
        :id="'hidden-input' + trans.id"
        v-model="trans.id"
        class="hidden-input"
      />
    </div>

    <cancelSettlement
      :status="deleteSettlement"
      :trans-id="trans.id"
      @close="deleteSettlement = false"
    />
  </tr>
</template>

<script>
import cancelSettlement from "../modals/cancelSettlement"
import { channels } from "@/mixins/channels"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "TableRow",
  components: {
    cancelSettlement,
  },
  mixins: [channels, screenSize],
  props: ["transaction"],
  data() {
    return {
      statusChannel: "",
      tooltip_show: false,
      mouseIsOnBtn: false,
      deleteSettlement: false,
      statusDescriptionResult: {},
    }
  },
  computed: {
    creditPort() {
      const mapper = {
        TARA: "پرداخت اعتباری تارا",
      }
      return mapper[this.trans.port] ?? ""
    },
    ipgCondition() {
      return [
        "transaction_ipg_successful",
        "transaction_ipg_pending",
        "transaction_ipg_unsuccessful",
        "transaction_revised_successful",
      ].includes(this.statusChannel)
    },
    creditCondition() {
      return [
        "transaction_credit_successful",
        "transaction_credit_pending",
        "transaction_credit_unsuccessful",
      ].includes(this.statusChannel)
    },
    depositCondition() {
      return [
        "transaction_requestMoney_unsuccessful",
        "transaction_requestMoney_pending",
        "transaction_requestMoney_successful",
      ].includes(this.statusChannel)
    },
    receiverBank() {
      return (
        [
          "settlement_tashim_successful_port1",
          "settlement_tashim_successful_port2",
          "settlement_tashim_successful_final",
          "settlement_tashim_successful_first",
          "settlement_tashim_pending",
          "settlement_tashim_unsuccessful",
          "settlement_tashim_returned",
          "settlement_tashim_refund_returned",
        ].includes(this.statusChannel) && !this.trans.refund
      )
    },
    payerChannel() {
      return [
        "transaction_pbv_successful",
        "transaction_pbv_unsuccessful",
        "transaction_pbv_pending",
      ].includes(this.statusChannel)
    },
    refundCondition() {
      return this.trans.refund
    },
    descriptionCondition() {
      return (
        this.statusChannel === "settlement_subscriptionReturned_successful" ||
        this.statusChannel === "transaction_fee_wallet_unsuccessful" ||
        this.statusChannel === "transaction_fee_wallet_successful" ||
        this.statusChannel === "transaction_fee_wallet_pending"
      )
    },
    revisedCondition() {
      return ["transaction_cancelRevised", "transaction_correction"].includes(
        this.statusChannel
      )
    },
    // eslint-disable-next-line vue/return-in-computed-property
    transactionInfoSubLine() {
      if (this.doseChannelMatch("credit", this.trans.channel)) {
        return {
          text: this.creditPort,
        }
      }
      if (this.statusChannel === "transaction_revised_successful")
        return {
          ...(this.trans.revised_transaction_id && {
            text:
              "اصلاحیه واریز به شناسه یکتای " +
              this.trans.revised_transaction_id,
          }),
        }
      else if (
        this.statusChannel === "transaction_ipg_transactionRevised_successful"
      )
        return {
          ...(this.trans.revised_transaction_id && {
            text:
              "اصلاح شده در واریز به شناسه یکتای " +
              this.trans.revised_transaction_id,
          }),
        }
      else if (
        this.statusChannel === "transaction_cashInByCode_successful" &&
        !this.trans.payer.iban
      ) {
        return {
          text: "فاقد شماره شبا مبدا",
          type: "transaction_cashInByCode_successful",
          icon: "info_red.svg",
        }
      } else if (
        [
          "settlement_tashim_returned",
          "settlement_tashim_refund_returned",
        ].includes(this.statusChannel)
      )
        return {
          ...(this.trans.revised_transaction_id && {
            text:
              "برگشت داده شده در واریز به شناسه یکتای " +
              this.trans.revised_transaction_id,
          }),
        }
      else if ([2, -2, -3].includes(this.trans.status) && !this.trans.refund)
        return {
          ...(this.trans.ref_id && { text: "کد رهگیری: " + this.trans.ref_id }),
        }
      else if (this.trans.refund || this.trans.status === -5)
        return {
          ...(this.trans.tracking_code && {
            text: "کد رهگیری: " + this.trans.tracking_code,
          }),
        }
    },
    trans() {
      return this.transaction
    },
    rowClass() {
      return {
        "succeed big": [1, 4, 5, 6, 7, 10],
        "failed big": [-1],
        "settlment_succeed big": [2, -5, 9],
        "settlment_failed big": [-3, -4, -6, -7, "FAILED", -8],
        "pending big": [-2, 3, -9],
      }
    },
    ipgText() {
      let text =
        this.trans.card_number?.length === 16
          ? " - " +
            this.trans.card_number.substring(12, 16) + // we add card number part by part
            this.trans.card_number.substring(6, 12) + // to avoid reversing in rtl direction
            this.trans.card_number.substring(0, 6)
          : ""

      return "درگاه پرداخت " + text
    },
    creditText() {
      return this.trans.channel
    },
    depositText() {
      let text = "واریزی " + this.trans.payer?.name

      if (this.trans.form_title) text += " - " + this.trans.form_title
      if (this.trans.card_number) text += " - " + this.trans.card_number

      return text
    },
    receiverText() {
      return (
        (this.trans?.receiver.name ?? "") +
        (this.trans?.receiver?.bank_name
          ? " - بانک " + this.trans?.receiver.bank_name
          : "")
      )
    },
    payerText() {
      return (
        (this.trans?.payer.name ? this.trans?.payer.name + " - " : "") +
        (this.trans?.channel ?? "")
      )
    },
    refundText() {
      return "بازگشت وجه تراکنش " + this.trans.refund?.source_transaction ?? ""
    },
    revisedText() {
      return (
        "واریز مبلغ «برداشت برگشتی» به شناسه یکتای" +
          this.trans.revised_transaction_id ?? ""
      )
    },
    descriptionText() {
      return this.trans.description
    },
    isShowCancelSettlementButton() {
      return (
        (this.trans.status === -2 || this.trans.status === -3) &&
        !this.trans.refund &&
        this.trans.settlement_port !== "2" &&
        !this.trans.time_prediction.is_after_cancelable
      )
    },
    transactionAmount() {
      return this.trans.status === 9 ||
        this.trans.status === -9 ||
        this.trans.channel === "FEE"
        ? this.trans.amount
        : this.trans.amount - this.trans.wage - this.trans.shaparakWage
    },
  },
  mounted() {
    this.statusDescription()
  },
  methods: {
    transactionInfo() {
      const mapper = {
        ipgCondition: "ipgText",
        creditCondition: "creditText",
        depositCondition: "depositText",
        payerChannel: "payerText",
        receiverBank: "receiverText",
        refundCondition: "refundText",
        revisedCondition: "revisedText",
        descriptionCondition: "descriptionText",
      }

      for (let element in mapper) {
        if (this[element]) {
          return this[mapper[element]]
        }
      }
      return this.trans.channel
    },
    getToolbarTitle() {
      let obj = {
        status: this.trans.status,
        channel: this.trans.channel,
        isRefund: this.trans.refund,
        isFinallySettlement:
          this.trans.time_prediction?.is_settlement_paya_report_finally,
        settlementPort: this.trans?.settlement_port,
        shaparakSuccess: this.trans?.tracking_code !== null,
      }

      this.statusChannel = this.statusChannels(obj)

      switch (this.statusChannel) {
        case "settlement_p2p_successful":
          return "برداشت موفق"
        case "transaction_p2p_successful":
        case "transaction_vandar_fee_reverse":
          return "واریز موفق"
        case "settlement_refund_pending":
          return "بازگشت وجه در صف بانک"
        case "settlement_refund_successful":
        case "settlement_vandar_fee":
          return "برداشت موفق"
        default:
          return (
            this.getKeyByValue(this.transactionsTitles, this.statusChannel) ??
            ""
          )
      }
    },
    statusDescription() {
      let result = {
        title: this.getToolbarTitle(),
      }

      //show wage?
      let flag = false

      if ([1, 6, 7, 2, -5, -3, -2, -6].includes(this.trans.status)) flag = true

      switch (this.trans.status) {
        case -1:
          if (this.trans.logs?.length !== 0)
            result.subTitle = this.trans.logs[0].group
          break
        case -4:
          if (this.trans.refund) {
            flag = true
          } else {
            result.subTitle = "لغو برداشت"
          }
          break
      }

      if (flag && this.trans.wage !== 0) {
        result.subValue = this.trans.wage
        result.subTitle = "کارمزد"
        result.type = "currency"
      }

      this.statusDescriptionResult = result
    },
    dateTime(date) {
      const moment = require("moment-jalaali")
      const currentDate = moment(date, "HH:mm:ss - jYYYY/jM/jD")
      return this.jalaliDate(currentDate, "jYYYY/jM/jD - HH:mm:ss")
    },
    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector("#hidden-input" + this.trans.id)
      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        //Alert the copied text
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    showDetail(item) {
      if (!this.mouseIsOnBtn) this.$emit("showDetail", item)
    },
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  tr {
    &:hover {
      td {
        &:first-child {
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
