<template>
  <v-row class="align-center" no-gutters>
    <v-col cols="3">
      <span class="font_31"> {{ title }} </span>
    </v-col>
    <v-col cols="9">
      <v-text-field
        v-model="formattedPrice"
        type="text"
        hide-spin-buttons
        :hide-details="isHiddenDetails"
        solo
        flat
        maxLength="19"
        @keydown="checkInputtedValue"
        @paste="handlePaste"
      >
        <template #append>
          <span class="font_30">{{ currencyName }}</span>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "PriceInput",
  props: {
    title: {
      type: String,
      default: "مبلغ از:",
    },
    isHiddenDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceValue: null,
    }
  },
  computed: {
    ...mapState("bankInvoice", ["shouldResetPriceInput"]),
    formattedPrice: {
      get() {
        return this.priceValue
          ? this.$options.filters.thousandSeprator(this.priceValue)
          : null
      },
      set(newValue) {
        this.priceValue = this.keepOnlyDigits(newValue)

        if (isNaN(this.priceValue)) {
          this.priceValue = null
        }
        this.$emit("send-up-price", {
          price: this.priceValue,
          formattedPrice: this.formattedPrice,
        })
      },
    },
  },
  watch: {
    shouldResetPriceInput(val) {
      if (val) this.formattedPrice = null
    },
  },
  methods: {
    checkInputtedValue(event) {
      const onlyNumbers = /^\d+$/

      if (
        !onlyNumbers.test(this.convertNumbers2English(event.key)) &&
        !this.allowedKeys(event)
      ) {
        event.preventDefault()
      }
    },
    handlePaste(event) {
      this.formattedPrice = this.handlePasteEventForNumericValues(event)
    },
  },
}
</script>

<style lang="scss" scoped></style>
